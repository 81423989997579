import { autoinject, computedFrom, inject } from "aurelia-framework";
import { AuthService } from "aurelia-auth";
import { WalletInfo, WalletsManager } from "services/WalletManager.service";
import { SingletonService } from "singleton";
import { P2PWallet } from "bsv-wallet";

@autoinject()
export class adminTest {
  private allCryptoWallets: WalletInfo[] = [];
  private currentCryptoWalletIndex: number;
  private walletsInfo: WalletInfo[];
  private wallet = P2PWallet;
  private amount: number = 0;
  private friendAddress: string = "";

  constructor(
    private singleton: SingletonService,
    private walletManager: WalletsManager
  ) {
    this.currentCryptoWalletIndex =
      this.singleton.getCurrentCryptoWalletIndex();
  }

  activate() {
    this.getWalletsInfos();
  }

  next() {
    this.currentCryptoWalletIndex += 1;
    this.getSelectedWallets(this.currentCryptoWalletIndex);
  }

  previous() {
    this.currentCryptoWalletIndex -= 1;
    this.getSelectedWallets(this.currentCryptoWalletIndex);
    this.getMyAdress(this.allCryptoWallets, this.currentCryptoWalletIndex);
  }

  async getWalletsInfos() {
    this.allCryptoWallets = await this.walletManager.getWalletsInfos();
    this.getCryptoTx(this.allCryptoWallets);
  }

  getSelectedWallets(current) {
    let singleWallet = this.walletManager.get(
      this.allCryptoWallets[current].name
    );
  }

  getCryptoTx(allCryptoWallets) {
    let currentWallet = allCryptoWallets[this.currentCryptoWalletIndex];
    this.getSelectedWallets(this.currentCryptoWalletIndex);
    this.getMyAdress(allCryptoWallets, this.currentCryptoWalletIndex);
    console.log(currentWallet);
  }

  async getMyAdress(allCryptoWallets, current) {
    let singleWallet = this.walletManager.get(
      this.allCryptoWallets[current].name
    );
    const balanceInSatoshis = await singleWallet.getBalance();

    console.log({ balanceInSatoshis });
  }

  async generateAddress(current) {
    let newAddress = this.walletManager.get;
  }

  async initiatePayment(allCryptoWallets, current) {
    let singleWallet = this.walletManager.get(
      this.allCryptoWallets[current].name
    );

    const txHex = await singleWallet.signTx({
      to: this.friendAddress,
      amount: this.amount,
    });

    const txId = await singleWallet.receive(txHex);
    const txUrl = `https://test.whatsonchain.com/tx/${txId}`;
  }

  async initatePeerToPeerPayments(allCryptoWallets, current) {
    let singleWallet = this.walletManager.get(
      this.allCryptoWallets[current].name
    );

    const txHex = await singleWallet.signTx({
      to: this.friendAddress,
      amount: this.amount,
    });

    const txId = await singleWallet.receive(txHex);
    const txUrl = `https://test.whatsonchain.com/tx/${txId}`;
  }

  /*     async testp2p(allCryptoWallets, current){
    // Sender side
    let singleWallet = this.walletManager.get(
      this.allCryptoWallets[current].name
    );

    const txHex: string = await singleWallet.signTx({
    to: this.friendAddress,
    amount: 0
  });
  // Send txHex to B through internet or other means
  

  // Receiver side<
  const offlineTx: string = await B.receive(txHex);
  const broadcast: Promise<void> = B.broadcast(txHex);
  
  console.log(offlineTx);
  console.log(broadcast);
  } */
}
