// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow-left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/arrow_right.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./admin_test.scss"></require>
  <require
    from="../../../components/card-banka-detailled/card-banka-detailled"
  ></require>
  <section id="admin_test" class="flex-column">
    <div class="card_container flex-row">
      <div class="arrow_container flex-column">
        <img src="${___HTML_LOADER_IMPORT_0___}" click.delegate="previous()" />
        <span class="btn-title">Previous</span>
      </div>
      <card-banka-detailled
        name.bind="allCryptoWallets[currentCryptoWalletIndex].name"
        crypto.bind="true"
        amount.bind="allCryptoWallets[currentCryptoWalletIndex].balance"
        blue.bind="true"
        click.delegate="router.navigateToRoute('choose_wallet')"
      >
      </card-banka-detailled>
      <div class="arrow_container flex-column">
        <img src="${___HTML_LOADER_IMPORT_1___}" click.delegate="next()" />
        <span class="btn-title">Next</span>
      </div>
    </div>
    <div>
      <p>\${allCryptoWallets[currentCryptoWalletIndex].address}</p>
    </div>
    <div class="flex-grow"></div>
    <form class="flex-column">
      <input value.bind="amount" class="input" type="number" placeholder="0.00\$" required />
      <input value.bind="friendAddress" class="input" type="text" placeholder="friendAddress" required />
    </form>
    <p>
      \${txUrl}
    </p>
    <div class="flex-grow"></div>
    <button class="btn btn-primary">
      <span>Fake button pour le moment</span>
    </button> 
    <button click.delegate="initiatePayment()" class="btn btn-primary">
      <span>Envoyer des Bsv</span>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;