// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./admin_report_bank.scss"></require>
  <section id="admin_report_bank" class="flex-column">
    <div id="header" class="flex-row">
      <img
        click.delegate="router.navigateToRoute('adminhome')"
        src="${___HTML_LOADER_IMPORT_0___}"
      />
      <h1>Bug report - Bank</h1>
    </div>
    <table>
      <tr>
        <th>Country</th>
        <th>Bank</th>
      </tr>
      <tr repeat.for="bankReport of bankReports">
        <td>\${bankReport.country}</td>
        <td>\${bankReport.bankName}</td>
      </tr>
    </table>
  </section>
</template>
`;
// Exports
export default code;