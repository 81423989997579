import { Router } from "aurelia-router";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { autoinject } from "aurelia-dependency-injection";
import { ReportsHttpClient } from "../../../http_clients/ReportsHttpClient";
import { ReportsModel } from "components/models/ReportsModel";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { BetaUser } from "components/models/BetaUserModel";
import { json } from "aurelia-fetch-client";

@autoinject
export class AdminReportBank {
  private bankReports;

  constructor(
    private router: Router,
    private reportHttpClient: ReportsHttpClient
  ) {}

  activate() {
    this.getAllBankReport();
  }

  async getAllBankReport() {
    let request = await this.reportHttpClient.fetch("/reportBank");
    let is200Ok = await checkResponseStatus(request);
    this.bankReports = await is200Ok.json();
  }
}
